import Footer from '../components/Footer'

function Layout({ children }) {
  return (
    <div className="main-app text-white min-h-screen">
      <div className="background-bottom z-0" />
      <div className="container max-w-sm lg:max-w-lg mx-auto min-h-screen flex flex-col justify-between items-center">
        {children}
        <Footer />
      </div>
    </div>
  )
}

export default Layout
