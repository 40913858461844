import { motion } from 'framer-motion'
import logo from '../assets/images/UExSB_Logo.png'

function Header({ headerStype }) {
  if (headerStype === 'big') {
    return (
      <motion.header
        layoutId="app__header"
        className="App-header text-center py-20 pb-12 relative"
      >
        <motion.img
          layoutId="app_logo"
          src={logo}
          alt="Black Uber Eats Logo"
          width="200px"
          height="69px"
          className="inline-block"
        />
      </motion.header>
    )
  }

  return (
    <motion.header
      layoutId="app__header"
      className="App-header text-center py-6 relative"
    >
      <motion.img
        layoutId="app_logo"
        src={logo}
        alt="Black Uber Eats Logo"
        width="140px"
        height="50px"
        className="inline-block"
      />
    </motion.header>
  )
}

export default Header
