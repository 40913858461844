import { BrowserRouter, Routes, Route } from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'
import { AnimatePresence } from 'framer-motion'
import NotFound from './views/NotFound'
import Terms from './views/Terms'
import Ended from './views/Ended'
import Layout from './components/Layout'
import './App.css'

smoothscroll.polyfill()

function App(props) {
  return (
    <div className="App">
      <Layout>
        <AnimatePresence>
          <BrowserRouter>
            <Routes>
              <Route index element={<Ended />} />
              <Route path="/prize" element={<Ended />} />
              <Route path="/second-chance" element={<Ended />} />
              <Route path="/ended" element={<Ended />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </AnimatePresence>
      </Layout>
    </div>
  )
}

export default App
