import React, { useEffect } from 'react'
import { pageview } from '../../helpers/gtag'
import Header from '../../components/Header'
import styled from '@emotion/styled'
import './style.css'

const StyledWrapper = styled.div`
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  table td .c3,
  table th .c3 {
    font-size: 12px;
    font-weight: 500;
  }

  @media only screen and (min-width: 600px) {
    table td .c3,
    table th .c3 {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .c4 {
    border-right-style: solid;
    padding: 0pt 5pt 0pt 5pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 150.8pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c6 {
    border-right-style: solid;
    padding: 0pt 5pt 0pt 5pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 144pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c11 {
    border-right-style: solid;
    padding: 0pt 5pt 0pt 5pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 148.5pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c16 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.45;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }
  .c3 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 16px;
    font-style: normal;
  }
  .c0 {
    margin-left: 4pt;
    padding-top: 8pt;
    padding-bottom: 8pt;
    line-height: 1.45;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c12 {
    padding-top: 12pt;
    padding-bottom: 0pt;
    line-height: 1.45;
    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .c7 {
    padding-top: 0pt;
    padding-bottom: 6pt;
    line-height: 1.45;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c5 {
    padding-top: 12pt;
    padding-bottom: 0pt;
    line-height: 1.45;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c9 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 16px;
    font-style: normal;
  }
  .c19 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 16px;
    font-style: normal;
  }
  .c18 {
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }
  .c20 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 2rem 0;
  }
  .c10 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
  }
  .c1 {
    font-weight: 700;
  }
  .c14 {
    font-weight: 400;
  }
  .c2 {
    color: inherit;
    text-decoration: inherit;
  }
  .c8 {
    font-weight: 400;
  }
  .c17 {
    color: #ff0000;
  }
  .c15 {
    color: #0000ff;
  }
  .c13 {
    height: 19.5pt;
  }
  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 22px;
    font-weight: bold;
    padding-bottom: 3pt;
    line-height: 1.6;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 16px;
    padding-bottom: 16pt;
    line-height: 1.45;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: #000000;
    font-size: 16px;
  }
  p {
    margin: 0;
    color: #000000;
    font-size: 16px;
  }
  h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 24px;
    padding-bottom: 6pt;
    line-height: 1.45;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16px;
    padding-bottom: 6pt;
    line-height: 1.45;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 16px;
    padding-bottom: 4pt;
    line-height: 1.45;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 16px;
    padding-bottom: 4pt;
    line-height: 1.45;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 14px;
    padding-bottom: 4pt;
    line-height: 1.45;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 10px;
    padding-bottom: 4pt;
    line-height: 1.45;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
`

function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0)
    pageview('/terms')
  }, [])

  return (
    <div className="inner-content relative" layout key="page-starter">
      <Header headerStype="big" />
      <div className="lg:max-w-lg mx-auto max-h-[600px] max-w-[360px] overflow-auto bg-white text-black p-8">
        <StyledWrapper>
          <div className="c20 doc-content">
            <p className="c12">
              <span className="title">Uber Eats Best Fall Ever Sweepstakes</span>
            </p>
            <p className="c12">
              <span className="c8">
                OFFICIAL RULES
                <br />
              </span>
              <span className="c1">
                NO PURCHASE OR PAYMENT IS NECESSARY TO ENTER OR TO WIN.
                <br />
              </span>
              <span className="c3">
                A PURCHASE DOES NOT IMPROVE ONE’S CHANCES OF WINNING.
                <br />
                VOID WHERE PROHIBITED BY LAW.
              </span>
            </p>
            <p className="c5">
              <span className="c10 c1">Eligibility:</span>
              <span className="c3">
                &nbsp;The Uber Eats Best Fall Ever Sweepstakes (“Sweepstakes”) is
                only open to permanent legal residents of the fifty (50) states of
                the United States ("U.S.") and the District of Columbia (“D.C.”) who
                are currently, physically located and residing therein (excluding
                Guam, Puerto Rico, and all other U.S. foreign territories and
                possessions). All entrants must be the age of majority (eighteen
                (18) years of age in most states or older) as of September 18, 2023.
                Entrants must have either a valid Social Security number and/or a
                valid U.S. Individual Taxpayer Identification Number. Employees of
                Uber Technologies, Inc., (“Sponsor”), Enteractive Solutions Group,
                Inc., (“Sweepstakes Administrator”), Starbucks Corporation, Kamp
                Grizzly, Inc., their advertising or promotion agencies, those
                involved in the production, development, implementation, or handling
                of the Sweepstakes, and any agents acting for the above entities,
                and their respective parent companies, officers, directors,
                subsidiaries, franchisees, affiliates, service providers, prize
                suppliers or any other person or entity associated with the
                Sweepstakes (collectively “Sweepstakes Entities”) are not eligible
                to enter the Sweepstakes. Immediate family members (i.e. a spouse,
                domestic partner, child, sibling, or parent) and/or any person,
                whether related or not, living in the same household of an
                individual affiliated with a Sweepstakes Entity are also ineligible
                to enter the Sweepstakes.
              </span>
            </p>
            <p className="c7">
              <span className="c10 c1">Sweepstakes Period:</span>
              <span className="c3">
                &nbsp;For purposes of the Sweepstakes, all times refer to Pacific
                (“PT”) and the Sponsor’s computer is the official clock. The
                Sweepstakes begins at 12:00:00 a.m. PT on September 18, 2023 and
                ends at 11:59:59 p.m. PT on September 27, 2023 (“Sweepstakes
                Period”) but various dates/deadlines apply (see chart below). The
                Sweepstakes will award 13,025 (thirteen thousand twenty-five) prizes
                as more fully described below.
              </span>
            </p>
            <table className="c18">
              <tbody>
                <tr className="c13">
                  <td className="c6" colSpan="1" rowSpan="1">
                    <p className="c0">
                      <span className="c9 c1">Event</span>
                    </p>
                  </td>
                  <td className="c4" colSpan="1" rowSpan="1">
                    <p className="c0">
                      <span className="c9 c1">Start</span>
                    </p>
                  </td>
                  <td className="c11" colSpan="1" rowSpan="1">
                    <p className="c0">
                      <span className="c1 c9">End</span>
                    </p>
                  </td>
                </tr>
                <tr className="c13">
                  <td className="c6" colSpan="1" rowSpan="1">
                    <p className="c0">
                      <span className="c3">Request/Obtain a Code</span>
                    </p>
                  </td>
                  <td className="c4" colSpan="1" rowSpan="1">
                    <p className="c0">
                      <span className="c3">9/18/2023 at 12:00:00 a.m. PT</span>
                    </p>
                  </td>
                  <td className="c11" colSpan="1" rowSpan="1">
                    <p className="c0">
                      <span className="c3">9/24/2023 at 11:59:59 p.m. PT</span>
                    </p>
                  </td>
                </tr>
                <tr className="c13">
                  <td className="c6" colSpan="1" rowSpan="1">
                    <p className="c0">
                      <span className="c3">Enter with a Code</span>
                    </p>
                  </td>
                  <td className="c4" colSpan="1" rowSpan="1">
                    <p className="c0">
                      <span className="c3">9/18/2023 at 12:00:00 a.m. PT</span>
                    </p>
                  </td>
                  <td className="c11" colSpan="1" rowSpan="1">
                    <p className="c0">
                      <span className="c3">9/27/2023 at 11:59:59 p.m. PT</span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="c7">
              <span className="c3">&nbsp;</span>
            </p>
            <p className="c5">
              <span className="c10 c1">How to Obtain a Code</span>
              <span className="c8">
                : To participate, entrants with an Uber Eats account must first
                obtain a code (“Code”) by visiting Uber Eats and successfully
                placing an order from the Starbucks menu valued at $20 or more. All
                applicable order fees and taxes still apply. Entrants will receive a
                Code via email within 24-48 hours after placing their order. The
                Code will be sent to the email used/associated with an entrant’s
                Uber Eats account. An entrant can obtain an Uber Eats account at no
                cost at {` `}
              </span>
              <span className="c10 c8 c15">
                <a
                  className="c2"
                  href="https://www.google.com/url?q=https://www.ubereats.com/&amp;sa=D&amp;source=editors&amp;ust=1694715722853219&amp;usg=AOvVaw0-NdRBAeVf2a0C4rRGSY48"
                >
                  https://www.ubereats.com/
                </a>
              </span>
              <span className="c8">
                &nbsp;or by downloading the app from the appropriate app store.{' '}
              </span>
              <span className="c1">
                Last day to obtain a Code via an Uber Eats order is at 11:59:59 p.m.
                PT on September 24, 2023.
              </span>
              <span className="c8">&nbsp;</span>
              <span className="c1">
                Limit one (1) Code request per person/email address/household per
                day.
              </span>
              <span className="c8">
                &nbsp;To receive a Code for free without placing an order, an
                entrant must go to {` `}
              </span>
              <span className="c10 c8 c15">
                <a
                  className="c2"
                  href="https://www.google.com/url?q=https://a.cstmapp.com/p/965190&amp;sa=D&amp;source=editors&amp;ust=1694715722853582&amp;usg=AOvVaw3_0xnQcGIGa6KQ-ieokwFY"
                >
                  https://a.cstmapp.com/p/965190
                </a>
              </span>
              <span className="c8">
                &nbsp;(“Code Website”) and follow the instructions to complete and
                thereafter submit the registration form by providing an Email
                Address (“Email Entry Information”). After registering the first
                time at the Code Website a verification email will be sent to the
                entrants containing a URL directing entrants back to the Code
                Website to obtain their free Code (“Free Code”).{' '}
              </span>
              <span className="c1">
                Free Code requests must be received by September 24, 2023 at
                11:59:59 p.m. PT.
              </span>
              <span className="c8">&nbsp;</span>
              <span className="c9 c1">
                Limit one (1) Free Code request per person/email address/household.
                Entrants can obtain both Codes and/or Free Codes (collectively,
                “Uber Codes”) but there is a limit of seven (7) entries request per
                person/email address/household total regardless of how an Uber Code
                is obtained. Uber Codes are not unique and an entrant may receive
                the same Uber Code previously sent.
              </span>
            </p>
            <p className="c5">
              <span className="c10 c1">How to Enter</span>
              <span className="c8">
                : After receiving an Uber Code, an entrant must go to {` `}
              </span>
              <span className="c10 c8 c15">
                <a
                  className="c2"
                  href="https://www.google.com/url?q=http://www.bestfallever.com/&amp;sa=D&amp;source=editors&amp;ust=1694715722854097&amp;usg=AOvVaw0sbQsgP3YgMq6NGL8L5-AO"
                >
                  www.bestfallever.com/
                </a>
              </span>
              <span className="c8">
                &nbsp;(“Sweepstakes Website”) to input their Code, agree to being
                the age of majority at the time of entry and to abide by these
                Official Rules and Sponsor’s Terms of Use via a checkbox. Said
                entrant will then receive one (1) entry to see if he/she is an
                instant winner (“Code Entry”). Thirteen thousand twenty-five
                (13,025) randomly computer-generated winning times will be
                programmed during the Sweepstakes Period (“Winning Instant Win
                Times”). The first entrant to enter after one of the predetermined
                Winning Instant Win Times will be the potential instant winner.
                Entrants who are potential instant winners will follow the
                instructions to complete and thereafter submit the following
                information: First Name, Last Name, Complete Mailing Address (no
                P.O. Boxes), Email Address, Phone Number, and agree that they are
                the age of majority at the time of entry and to abide by these
                Official Rules and Sponsor’s Terms of Use via a checkbox
                &nbsp;(“Online Entry Information”). If an entrant is an instant
                winner, he/she will be notified of his/her winning status after
                providing his/her Online Entry Information and will thereafter be
                prompted with instructions for how to redeem the prize. If an
                entrant is not an instant winner, he/she will have the option to be
                entered into the second chance drawing for any un-awarded prizes by
                submitting the following information: First Name, Last Name,
                Complete Mailing Address (no P.O. Boxes), Email Address, Phone
                Number, and agree to being the age of majority at the time of entry
                and to abide by these Official Rules and Sponsor’s Terms of Use via
                a checkbox (“Online Second Chance Entry Information”). After
                completing and submitting the Online Second Chance Entry
                Information, said entrant will then receive one (1) entry into the
                second chance drawing (“Second Chance Entry”).{' '}
              </span>
              <span className="c1">
                Limit one (1) Code Entry and Second Chance Entry (collectively
                “Entry”) per person/email address/household per day for a total of
                seven (7) Entries per person/email address/household for the entire
                Sweepstakes.{' '}
              </span>
              <span className="c8">
                If all prizes have been awarded prior to the end of the Sweepstakes
                Period, a message stating such will be clearly posted on the
                Sweepstakes Website and no additional prizes will be awarded and no
                second chance drawing will occur.{' '}
              </span>
              <span className="c9 c1">
                The last day to submit an Entry and input an Uber Code at the
                Sweepstakes Website is 11:59:59 p.m. PT on September 27, 2023.
              </span>
            </p>
            <p className="c5">
              <span className="c8">
                All required Email Entry Information, Online Second Chance Entry
                Information, and/or Online Entry Information (collectively “Entry
                Information”) must be completed in full, be accurate, and valid. The
                Entry Information
              </span>
              <span className="c8 c15">&nbsp;</span>
              <span className="c3">
                must match the information of the entrant that completed the act of
                physically entering the Sweepstakes. The Sweepstakes Website and
                Code Website (collectively “Website”) may not be accessible from all
                mobile phones, handheld devices, and/or tablets. If an entrant
                experiences any issues entering, he/she should access the
                Sweepstakes Website from a desktop or laptop computer. If entrants
                use a web-enabled mobile device to participate, data rates may apply
                (entrants should contact their service provider for data
                rates/plans). Entrants should use care and not drive or operate
                machinery while entering the Sweepstakes. Entries in excess of the
                stated limits will be disqualified. Proof of submitting an Entry
                will not be deemed by Sponsor as proof of receipt or entry into the
                Sweepstakes. Any attempt by an entrant to obtain more than the
                stated number of Entries by using multiple/different email
                addresses, identities, registrations, logins, AI generative
                technology, or any other methods other than described herein,
                including, but not limited to, commercial contest/sweepstakes
                subscription notification and/or entering services, and only the
                first valid Entry(ies) received will be eligible while all other
                Entries will be disqualified.
              </span>
            </p>
            <p className="c5">
              <span className="c10 c1">Prizes/Odds of Winning:</span>
              <span className="c1">&nbsp;</span>
              <span className="c8">
                Thirteen thousand twenty-five (13,025) prizes will be awarded in
                this Sweepstakes. The prize consists of a maroon and white checkered
                scarf valued at $40. Actual brand/model of prize is determined in
                Sponsor’s sole discretion and subject to availability.{' '}
              </span>
              <span className="c1">
                Total approximate retail value (“ARV”) of all prizes: $521,000. Odds
                of winning a prize depends on the total number of eligible Entries
                received throughout the Sweepstakes Period. Odds of winning an
                unclaimed prize (as outlined below) depends on how many prizes are
                claimed/awarded throughout the Sweepstakes Period.{' '}
              </span>
              <span className="c8">
                Limit one (1) prize per person/email address/household for the
                entire Sweepstakes Period.
              </span>
              <span className="c1">&nbsp;</span>
              <span className="c8">
                All expenses associated with the receipt or use of a prize not
                specifically mentioned as being included (e.g. any requested
                upgrades/options) are excluded and are the sole responsibility of
                each winner. No cash equivalent for prize(s) will be available.
                Prize(s) are not transferable. However, Sponsor may, in its sole
                discretion, choose to substitute a prize of equal or greater value
                due to prize unavailability or for any other reason. Sponsor
                reserves the right, in its sole discretion, to replace any prize
                with a similar version; but, in such event, Sponsor will continue to
                use the value of the “old” (i.e., replaced) item for purposes of
                calculating the ARV. As applicable, prize(s) will only be
                mailed/shipped/electronically delivered to persons residing within
                the fifty (50) states of the U.S. (including D.C.). Any depiction of
                prize(s) in promotional materials or otherwise, is for illustrative
                purposes only. All federal, state or other tax liabilities
                (including but not limited to income taxes) arising from
                participation in the Sweepstakes or award of any prize(s)
              </span>
              <span className="c8 c17">&nbsp;</span>
              <span className="c3">
                will be the sole responsibility of the winner(s). Final eligibility
                for the award of any prize is subject to eligibility verification,
                as set forth in these Official Rules. The Sweepstakes Administrator
                is not the supplier or guarantor of any prize.
              </span>
            </p>
            <p className="c5">
              <span className="c10 c1">Second Chance Drawing:</span>
              <span className="c3">
                &nbsp;If no entrant enters at or after any one of the thirteen
                thousand twenty-five (13,025) Winning Instant Win Times, then those
                prize(s) will be awarded via a random drawing from among all
                eligible Entries on or about October 2, 2023 (“Second Chance Drawing
                Date”). The second chance drawing will be randomly conducted by the
                Sweepstakes Administrator, an independent compliance organization.{' '}
              </span>
            </p>
            <p className="c5">
              <span className="c10 c1">Notification/Verification</span>
              <span className="c1">:</span>
              <span className="c3">
                &nbsp;Potential winners who input a Code at a Winning Instant Win
                Time will be notified immediately via an automated message after
                entering. If he/she is found to be ineligible; if he/she does not
                comply with these Official Rules; or if his/her prize or prize
                notification is returned as undeliverable, potential &nbsp;winner
                will be disqualified and an alternate potential winner of such prize
                may be selected in Sponsor’s sole discretion. Potential winners
                determined from the second chance drawing will be notified of their
                winning status upon receipt of the prize that will be automatically
                sent to them via the address provided on the entry form. If a
                potential second chance drawing winner is found to be ineligible; if
                he/she does not comply with these Official Rules; or if his/her
                prize is returned as undeliverable, potential winner(s) will be
                disqualified and an alternate potential winner(s) of such prize may
                be selected (in Sponsor’s sole discretion) from all eligible Entries
                received.
              </span>
            </p>
            <p className="c5">
              <span className="c10 c1">Privacy Policy: </span>
              <span className="c8">
                Any personal information supplied by entrants to Sponsor or its
                affiliates will be subject to Sponsor’s Privacy Policy and terms of
                service posted at the Website. Thus, except where prohibited by law
                and Sponsor’s Privacy Policy located at {` `}
              </span>
              <span className="c10 c8 c15">
                <a
                  className="c2"
                  href="https://www.google.com/url?q=https://www.uber.com/legal/privacy&amp;sa=D&amp;source=editors&amp;ust=1694715722856005&amp;usg=AOvVaw11V6PoWmC8ZxQjSci9UTTR"
                >
                  https://www.uber.com/legal/privacy
                </a>
              </span>
              <span className="c3">
                &nbsp;(1) entry and/or winner’s acceptance of prize constitutes
                permission for the Sweepstakes Entities to use a winner’s name,
                address (city and state), photo, video, likeness, biographical
                information, statement and voice for advertising/publicity purposes
                worldwide and in all forms of media (including posting on Website),
                in perpetuity, without further notice to or compensation in
                connection with said Sweepstakes or the prize awarded and (2)
                Sponsor has the right to modify, edit, adapt, electronically alter,
                use, assign and/or dispose of such Entries however it sees fit and
                for any purpose without any notice or compensation to entrant or any
                third parties. Entrants understand, agree, and consent that any
                personally identifiable information provided to the Sweepstakes
                Administrator may be retained and used for the purposes of this
                Sweepstakes (including but not limited to any applicable record
                keeping, tax forms, state registrations, and/or any prize
                fulfillment related obligations). The Sweepstakes Administrator does
                not and will not sell the personally identifiable information of any
                entrant or winner. Furthermore, the Sweepstakes Administrator will
                not share or disclose any personally identifiable information to any
                third-party company or business unrelated to the Sweepstakes.
                Additionally, if an entrant chooses to opt-in to receive
                communications from a participating Sweepstakes Entity, his/her
                Entry Information will be subject to that Sweepstakes Entity’s
                privacy policy and terms of service (if applicable), and such
                entrant agrees to receive additional email communication from that
                particular Sweepstakes Entity. Opting in to receive communications
                does not improve one’s chances of winning any prize. The privacy
                policy for any Sweepstakes Entity participating in an opt-in will be
                accessible from the Sweepstakes entry form.{' '}
              </span>
            </p>
            <p className="c5">
              <span className="c10 c1">General Conditions:</span>
              <span className="c3">
                &nbsp;The Sweepstakes is governed by these Official Rules and all
                applicable federal, state, &nbsp;and local laws. By entering the
                Sweepstakes, entrants agree and acknowledge to be bound by these
                Official Rules, as well as the decisions of the Sweepstakes
                Administrator which are final and binding in all respects; to waive
                any rights to claim ambiguity with these Official Rules; to
                indemnify and hold harmless the Sweepstakes Entities from any and
                all liability resulting or arising from the Sweepstakes or the use
                of any submitted Entry; and to release all rights to bring any
                claim, action or proceeding against the Sweepstakes Entities. Once
                submitted, Entries become the sole property of the Sponsor and will
                not be returned. Sponsor reserves the right, in its sole discretion,
                to disqualify any individual found to be tampering with the entry
                process or the operation of the Sweepstakes; to be acting in
                violation of the Official Rules; or to be acting in an
                unsportsmanlike or disruptive manner, or with intent to annoy,
                abuse, threaten or harass any other person. Any person attempting to
                defraud or tamper with Sweepstakes will be prosecuted and is not
                eligible for prizes. If for any reason, the Sweepstakes is not
                capable of running as originally planned, Sponsor in its sole
                discretion, reserves the right to cancel, suspend or modify the
                Sweepstakes or any portion(s) thereof and instruct the Sweepstakes
                Administrator to conduct a drawing(s) and award prizes(s) from all
                salvageable eligible Entries received prior to and/or after (as
                appropriate) the action taken by the Sponsor, or award the prize(s)
                in a manner that is fair and equitable. Notice of such action by the
                Sponsor will be posted on the Website as well as any applicable
                social media platforms. In case of a dispute over the identity of an
                entrant who submitted a winning Entry, the authorized account holder
                of the email address associated with that Entry will be deemed the
                entrant. Said person must comply with these Official Rules, be the
                person who completed the act of entering, and, in Sponsor’s sole
                discretion, may be required to provide proof of ownership of the
                email account.
              </span>
            </p>
            <p className="c5">
              <span className="c10 c1">Disclaimers:</span>
              <span className="c3">
                &nbsp;Sweepstakes Entities are not responsible or liable for entries
                that are entered by other than human means (such as by an automated
                computer program or any non-human mechanism, entity, or device), in
                excess of the stated limit, or for Entries/Code requests that are
                illegible, late, tampered with, forged, incomplete, misdirected,
                deleted, damaged, lost, misplaced, stolen, destroyed, dropped,
                inaccessible, corrupted, jumbled, or otherwise not in compliance
                with these Official Rules and such Entries/Code requests will be
                disqualified. By entering the Sweepstakes, entrants agree and
                acknowledge that the Sweepstakes Entities shall have no
                responsibility or liability (including, but not limited to,
                liability for any property loss, damage, personal injury or death)
                in connection with: participation in this Sweepstakes; human error;
                incorrect or inaccurate transcription of Entry Information/Code;
                acceptance/possession, use/misuse, and/or defects of the prize(s)
                awarded herein; typographical errors in Sweepstakes promotional
                material; any technical malfunctions of the telephone network,
                computer online system, computer dating mechanism, computer
                equipment, software, or internet service provider; any technical or
                mechanical error or issue when seeding/posting/uploading Codes to
                the Website; interruption or inability to access the Website (or
                Sponsor’s/Sweepstakes Administrator’s inability to access content on
                the Website), or any website or online service via the internet due
                to hardware or software compatibility problems; wireless network
                dead zones or obstructions; any damage to an entrant’s (or any third
                person’s) computer/mobile device and/or its contents related to or
                resulting from any part of this Sweepstakes or from entering or
                downloading materials/software in connection with the Sweepstakes;
                any lost/delayed data transmissions, omissions, interruptions,
                defects or any other errors or malfunctions even if caused by the
                negligence of one of the Sweepstakes Entities. Entrants hereby
                acknowledge that said Sweepstakes Entities have neither made nor are
                in any manner responsible or liable for any warranty, representation
                or guarantee, express or implied, in fact, or in law, relative to
                any prize, including express warranties provided exclusively by a
                prize supplier that are sent along with a prize. No more than the
                stated number of prizes will be awarded. In the case of any
                irregular or defective Codes, Sponsor’s sole liability is
                replacement with another randomly selected Code. In the event that
                production, technical, mechanical, typographical, seeding,
                programming, or any other reasons cause more than stated number of
                prizes as set forth in these Official Rules to be available and/or
                claimed, Sponsor reserves the right, in its sole and absolute
                discretion, to award only the stated number of prizes (previously
                not awarded) by means of a random drawing among all legitimate and
                eligible claims. Sweepstakes Entities shall not be liable for any
                injury, damage, loss, expense, accident, delay, inconvenience, or
                irregularity that may be caused by or have contributed to (1) any
                wrongful, negligent, or unauthorized act or omission on the part of
                a prize supplier (if applicable) or any of its agents, servants,
                employees or independent contractors, (2) any wrongful, negligent or
                unauthorized act or omission on the part of any other person or
                entity not an employee of Sponsor, or (3) any other cause, condition
                or event whatsoever beyond the control of Sponsor or its parents,
                subsidiaries and affiliated companies. The failure of Sponsor to
                comply with any provision of these Official Rules due to an act of
                God, hurricane, war, fire, demonstrations, riot, earthquake,
                terrorism, act of public enemies, strikes, epidemics, pandemics,
                actions of governmental authorities outside of the control of
                Sponsor (excepting compliance with applicable codes and regulations)
                or other force majeure event will not be considered a breach of
                these Official Rules.
              </span>
            </p>
            <p className="c5">
              <span className="c10 c1">
                Governing Law, Jurisdiction, Arbitration:
              </span>
              <span className="c1">&nbsp;</span>
              <span className="c3">
                Any controversy or claim arising out of or relating to (i) the
                Sweepstakes, (ii) the awarding or redemption of any prize and/or
                (iii) the determination of the scope or applicability of these
                Official Rules or their enforcement or interpretation shall be
                governed by and construed in accordance with the substantive laws of
                the state of California without giving effect to any choice of law
                or conflict of law rules or provisions that would cause the
                application of any other state’s laws. Any controversy or claim
                arising out of or relating to this agreement, its enforcement,
                arbitrability, or interpretation shall be submitted to final and
                binding arbitration, to be held in San Francisco County, California
                before a single arbitrator, in accordance with California Code of
                Civil Procedure §§ 1280 et seq. The arbitrator shall be selected by
                mutual agreement of Sponsor(s) and the entrant or entrants bringing
                forth the controversy or claim, which to the extent permissible must
                be brought individually and not as part of a className/group
                (collectively, the “Parties”) or, if the Parties cannot agree, then
                by striking from a list of arbitrators supplied by JAMS/Endispute.
                The arbitration shall be a confidential proceeding, closed to the
                general public. The arbitrator shall issue a written opinion stating
                the essential findings and conclusions upon which the arbitrator’s
                award is based. The Parties will share equally in payment of the
                arbitrator’s fees and arbitration expenses and any other costs
                unique to the arbitration hearing (recognizing that each side bears
                its own deposition, witness, expert and attorneys’ fees and other
                expenses to the same extent as if the matter were being heard in
                court). Entrant(s) agrees that his/her claim will be resolved
                individually, exclusively by arbitration, without resort to any form
                of className action. Each entrant(s) further agrees that any
                claim/judgment/award in such arbitration shall be limited to actual
                out-of-pocket costs incurred, including costs associated with
                participating in the Sweepstakes, and in no event will entrant(s) be
                permitted to obtain attorneys' fees, other legal costs and/or be
                entitled to awards. Entrant(s) hereby waives all rights to claim
                punitive, incidental, and consequential damages and any other
                damages, other than for actual out-of-pocket expenses, and any and
                all rights to have damages multiplied or increased. Should any term
                of this section be deemed by a tribunal of competent jurisdiction
                void, unenforceable, or contrary to law, such term shall, but only
                to the extent necessary to bring this section within the
                requirements of law, be deemed to be severed from the other terms of
                these Official Rules, and the remainder of these Official Rules
                shall be given effect as if it had not included the severed term
                herein. THE PARTIES HEREBY WAIVE THEIR RIGHT TO JURY TRIAL WITH
                RESPECT TO ALL CLAIMS AND ISSUES ARISING OUT OF OR RELATING TO THIS
                AGREEMENT WHETHER SOUNDING IN CONTRACT OR TORT AND INCLUDING ANY
                CLAIM FOR FRAUDULENT INDUCEMENT THEREOF.
              </span>
            </p>
            <p className="c5">
              <span className="c10 c1">Rules/Name of Winners</span>
              <span className="c8 c10">:</span>
              <span className="c3">
                &nbsp; To receive the name of the winner(s) or a copy of these
                Official Rules, send a self-addressed stamped envelope to: Uber Eats
                Best Fall Ever Sweepstakes “Winners List” or “Rules” (specify
                which), P.O. Box 6375, Burbank, CA 91510. All requests must be
                received by November 27, 2023. The winner(s) may also be posted on
                the Website.
              </span>
            </p>
            <p className="c5">
              <span className="c10 c1">Sponsor:</span>
              <span className="c3">
                &nbsp;Uber Technologies, Inc, 1515 Third Street, San Francisco, CA
                94158.
              </span>
            </p>
            <p className="c5">
              <span className="c10 c1">Sweepstakes Administrator:</span>
              <span className="c1">&nbsp;</span>
              <span className="c3">
                Enteractive Solutions Group, Inc., 1612 W. Olive Ave., Suite 300,
                Burbank, CA 91506.
              </span>
            </p>
            <p className="c5">
              <span className="c3">
                Starbucks is not responsible for the administration of the
                Sweepstakes.
              </span>
            </p>
            <p className="c5">
              <span className="c8 c19">
                ©2023 ESG. These Official Rules may only be copied for personal use
                and not for any commercial purpose whatsoever. All rights reserved.
              </span>
            </p>
            <p className="c16">
              <span className="c9 c14"></span>
            </p>
          </div>
        </StyledWrapper>
      </div>
    </div>
  )
}

export default Terms
