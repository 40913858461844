function Footer() {
  return (
    <footer className="relative z-4 pt-16 pb-16 text-white text-center flex">
      <a
        className="text-white font-medium p-2 md:p-4"
        href="/terms"
        rel="noopener noreferrer"
      >
        Official Rules
      </a>

      <a
        className="text-white font-medium p-2 md:p-4"
        href="https://www.uber.com/legal/en/document/?country=united-states&lang=en&name=privacy-notice"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
      <a
        href="mailto:info@bestfallever.com"
        className="footer-link contact-link p-2 md:p-4 font-medium"
      >
        Contact
      </a>
    </footer>
  )
}

export default Footer
