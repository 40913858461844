import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
//import NotWinning from '../../assets/images/not-winning.png'
import { event, pageview } from '../../helpers/gtag.js'
import Header from '../../components/Header'

function Welcome(props) {
  useEffect(() => {
    pageview('/404')
  }, [])

  const onEnterPrizeClick = () => {
    event({
      action: 'click',
      category: 'Click Button',
      label: 'Home page - click Enter now',
    })
  }

  return (
    <div className="not-winning app-container">
      <Header headerStype="big" />
      <div className="promo-content my-8">
        <h1 className="text-4xl font-bold text-center">
          {'<'}404{'>'}
        </h1>
      </div>
      <div className="btn-wrapper my-4 text-center">
        <Link to="/" className="btn" onClick={onEnterPrizeClick}>
          Go Home Page
        </Link>
      </div>
    </div>
  )
}

export default Welcome
