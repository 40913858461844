import { motion } from 'framer-motion'
import Header from '../../components/Header'

function Ended({ onButtonClick = () => {} }) {
  return (
    <motion.div className="inner-content relative" layout key="page-starter">
      <Header headerStype="big" />
      <motion.div className="text-center" layoutId="container">
        <motion.h1 className="text-center text-4xl font-bold">
          Best Fall Ever
          <br />
          promotion has <br />
          ended
        </motion.h1>
      </motion.div>
    </motion.div>
  )
}

export default Ended
